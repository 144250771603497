import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { Box } from '@primer/react';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Dont = makeShortcode("Dont");
const Caption = makeShortcode("Caption");
const DoDontContainer = makeShortcode("DoDontContainer");
const Do = makeShortcode("Do");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`When designing a feature onboarding flow, remember to:`}</p>
    <ul>
      <li parentName="ul">{`✅ Think creatively utilizing different ways to onboard users, including patterns that are not explicitly mentioned in this document.`}</li>
      <li parentName="ul">{`✅ Create a well-rounded feature release campaign that may leverage both on and off-product avenues.`}</li>
      <li parentName="ul">{`🚫 Do not leave feature announcement campaigns running forever.`}</li>
    </ul>
    <h2>{`Usage guidelines`}</h2>
    <h3>{`Proximity and proportion`}</h3>
    <ul>
      <li parentName="ul">{`Onboarding elements should be close to where the feature will live in perpetuity.`}</li>
      <li parentName="ul">{`Consider the primary task on a page before disrupting the user with a feature callout.`}</li>
      <li parentName="ul">{`Keep in mind the context, placement, and dominance of an onboarding element.`}</li>
      <li parentName="ul">{`Onboarding UI choices should be proportional to the functional hierarchy of the feature on the page.`}</li>
    </ul>
    <h3>{`Do not derail or trap the user`}</h3>
    <ul>
      <li parentName="ul">{`Make it clear to the user how they may dismiss the message and return to their original task quickly.`}</li>
      <li parentName="ul">{`Maintain context if a call-to-action links to a new page, i.e. open the link in a new tab or window to avoid losing a user's work.`}</li>
    </ul>
    <h3>{`Set clear campaign limits`}</h3>
    <p>{`Create a new feature release timeline with clear parameters and an appropriate ramp down plan:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Define why`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`System triggers, e.g. a new feature released, a user should not need to dismiss the same announcement each time they visit a different repository.`}</li>
          <li parentName="ul">{`User triggers, e.g. a user visits a specific repository for the first time, if recurring, a user should feel added value each time they see the message.`}</li>
          <li parentName="ul">{`Consider how triggers may be tallied for users who belong to multiple repositories and organizations.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Define when`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Timebox to a maximum number of days a coordinated campaign will run across all targeted users.`}</li>
          <li parentName="ul">{`Specify a maximum number of impressions a user should see an in-product message. Respect when a user dismisses a message.`}</li>
        </ul>
      </li>
    </ul>
    <h3>{`Avoid traffic collisions`}</h3>
    <ul>
      <li parentName="ul">{`Avoid showing too many alerts at the same time. Collisions are awkward and undesirable, no more than 2 alerts a time.`}</li>
      <li parentName="ul">{`Consider what other alerts, teaching bubbles, or banners may be active on the page when crafting an onboarding experience.`}</li>
    </ul>
    <Dont mdxType="Dont">
  <img src="https://user-images.githubusercontent.com/66705495/92283281-6e48a500-eeb4-11ea-9b3c-5f3a95ef0479.png" alt="Screenshot of two teaching bubbles overlapping" />
  <Caption mdxType="Caption">Don't forget to check coinciding feature releases on the same page.</Caption>
    </Dont>
    <h3>{`Create efficient sequences`}</h3>
    <p>{`Sequence tasks in a logical way to increase onboarding success. Clearly define when a user starts and successfully completes an onboarding flow.`}</p>
    <p>{`Treat feature onboarding as a story or a journey with a beginning, middle, and end.`}</p>
    <h4>{`Beginning: Draw attention to the feature`}</h4>
    <ul>
      <li parentName="ul">{`Invite the user to engage with an onboarding journey.`}</li>
      <li parentName="ul">{`Use teaching bubbles, alert banners, the feature preview tray or announcement modals to draw attention to the feature.`}</li>
    </ul>
    <DoDontContainer stacked mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://user-images.githubusercontent.com/66705495/92768625-0929ff00-f34d-11ea-9524-bb3f88a4681e.png" alt="Example of the Blank Slate component in the Wiki tab of a repository." />
    <Caption mdxType="Caption">
      Use empty states or alert banners to call attention to a feature if users may discover the feature when starting a
      task.
    </Caption>
  </Do>
  <Do mdxType="Do">
    <img src="https://user-images.githubusercontent.com/66705495/92768727-2363dd00-f34d-11ea-84bf-36c2f3670589.png" alt="Example of a teaching bubble pointing out a new collaboration tool on GitHub" />
    <Caption mdxType="Caption">
      Use teaching bubbles or other easily dismissable elements if users will discover the feature when they are
      completing another task.
    </Caption>
  </Do>
    </DoDontContainer>
    <h4>{`Middle: Guide users through their task`}</h4>
    <ul>
      <li parentName="ul">{`Use form, checklist, or other components to help users complete their task.`}</li>
    </ul>
    <Box display="flex" alignItems="center" flexDirection="column" my={3} mdxType="Box">
  <Box borderWidth="1px" borderStyle="solid" borderColor="border.default" borderRadius={2} width={9 / 12} mb={2} overflow="hidden" mdxType="Box">
    <img src="https://user-images.githubusercontent.com/6744014/89254411-e8df8580-d5d3-11ea-969f-5080a8c6b0d4.png" alt="Example of a checklist on GitHub with two uncompleted tasks and one completed task" />
  </Box>
  <p>Example of a checklist.</p>
    </Box>
    <h4>{`End: Celebrate the finish`}</h4>
    <ul>
      <li parentName="ul">{`Clearly define when a user starts and successfully completes an onboarding flow.`}</li>
      <li parentName="ul">{`Use a banner, or other messages to confirm the user has completed their task. Messages should be short and concise.`}</li>
    </ul>
    <h2>{`Teaching bubble`}</h2>
    <p>{`A teaching bubble is a `}<a parentName="p" {...{
        "href": "https://primer.style/css/components/popover"
      }}>{`popover`}</a>{` that calls attention to a feature in a specific part of the page. Generally, teaching bubbles should be used to educate the user and enrich the task at hand.`}</p>
    <h3>{`Usage guidelines for teaching bubble`}</h3>
    <p>{`Teaching bubble Figma `}<a parentName="p" {...{
        "href": "https://www.figma.com/file/Y2xJLFBrU7yyiDLlEkQXcF/Primer-Interfaces?node-id=2%3A1295&t=2xX6FwLSJ0Cq9Qkp-1"
      }}>{`sticker sheet`}</a>{`.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
      <Do mdxType="Do">
        <ul>
          <li parentName="ul">{`Highlight a new feature or important feature that extends or improves the user's experience on the page.`}</li>
          <li parentName="ul">{`Show one teaching bubble at a time.`}</li>
          <li parentName="ul">{`Include a headline that states the purpose of the message.`}</li>
          <li parentName="ul">{`Keep messages short and concise, around 160 characters.`}</li>
          <li parentName="ul">{`Make it clear how to easily dismiss the popover.`}</li>
          <li parentName="ul">{`Include supplementary information when applicable. Consider a "Learn more" link in the message body that opens a new window or tab without destroying a user's work or context.`}</li>
        </ul>
      </Do>
      <Dont mdxType="Dont">
        <ul>
          <li parentName="ul">{`Don't point to hidden elements.`}</li>
          <li parentName="ul">{`Don't use a teaching bubble if the user cannot immediately interact or benefit from the highlighted feature.`}</li>
          <li parentName="ul">{`Don't derail users; call-to-actions should not remove the user from their current context.`}</li>
        </ul>
      </Dont>
    </DoDontContainer>
    <h3>{`Variations`}</h3>
    <table>
  <tr>
    <td></td>
    <th>Example</th>
    <th>Notes</th>
  </tr>
  <tr>
    <th>Default</th>
    <td>
      <img src="https://user-images.githubusercontent.com/6744014/89239384-dc483680-d5ad-11ea-9463-3c56e1feefd9.png" alt="Example of a default teaching bubble" />
    </td>
    <td>
      Generally, use a descriptive dismiss button over a close icon. "OK, got it" is the default dismissal button copy.
    </td>
  </tr>
  <tr>
    <th>Condensed</th>
    <td>
      <img src="https://user-images.githubusercontent.com/6744014/89239379-db170980-d5ad-11ea-8327-fa8b035f5e0c.png" alt="Example of a condensed teaching bubble" />
    </td>
    <td>
      Use a close icon for condensed messages. Remember to apply the correct tab order for screen readers to easily
      dismiss the teaching bubble at the end of the message.
    </td>
  </tr>
  <tr>
    <th>Multiple buttons</th>
    <td>
      <img src="https://user-images.githubusercontent.com/6744014/89239536-58427e80-d5ae-11ea-9578-05c74c7701da.png" alt="Example of a teaching bubble with multiple buttons" />
    </td>
    <td>
      Invite the user to engage with the value proposition promised. Include dismiss as a secondary button as "OK,
      dismiss". Additional links that takes the user to a new page should be linked from the text and must open in a new
      tab or window to prevent losing the user’s context.
    </td>
  </tr>
  <tr>
    <th>Emoji</th>
    <td>
      <img src="https://user-images.githubusercontent.com/6744014/89239385-dc483680-d5ad-11ea-97e9-9ea6f54fe451.png" alt="Example of a teaching bubble with an emoji" />
    </td>
    <td>Use emojis to inject personality into the message.</td>
  </tr>
  <tr>
    <th>Pictograms</th>
    <td>
      <img src="https://user-images.githubusercontent.com/6744014/89239387-dce0cd00-d5ad-11ea-898b-b574bb837836.png" alt="Example of a teaching bubble with pictograms" />
      <img src="https://user-images.githubusercontent.com/6744014/89239389-dd796380-d5ad-11ea-84f4-c0819100fc2a.png" alt="Example of a teaching bubble with a pictogram" />
    </td>
    <td>Use pictograms to represent the feature.</td>
  </tr>
  <tr>
    <th>Illustrations and gifs</th>
    <td>
      <img src="https://user-images.githubusercontent.com/6744014/90083962-d39be280-dcc8-11ea-9d38-4bc24f759ba9.gif" alt="Example of a teaching bubble with a GIF" />
    </td>
    <td>Visually communicate or demonstrate a complex feature with illustrations and gifs to supplement a message.</td>
  </tr>
    </table>
    <h2>{`Inline feature discovery`}</h2>
    <p>{`Inline feature discovery is a way to highlight features without obscuring other parts of the page while a user may be performing an existing task.`}</p>
    <h3>{`Page banner`}</h3>
    <p>{`Use page banners for announcements that are relevant to the core task on the current page. Remember to consider using other feature announcement patterns or marketing strategies instead of disturbing the user with multiple announcements.`}</p>
    <p>{`Include a clear way for users to dismiss a page banner and `}<a parentName="p" {...{
        "href": "#set-clear-campaign-limits"
      }}>{`clear campaign parameters`}</a>{`.`}</p>
    <DoDontContainer stacked mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://user-images.githubusercontent.com/66705495/92768839-41314200-f34d-11ea-8f62-dfca0d43adf0.png" alt="Example of a page banner on the code tab of a repository" />
    <Caption mdxType="Caption">
      Call out a major feature or change in a page with an invitation to engage and a clear way to dismiss.
    </Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://user-images.githubusercontent.com/66705495/92768935-56a66c00-f34d-11ea-8b78-18ca99bd0350.png" alt="Example of a page banner pointing out a small feature change" />
    <Caption mdxType="Caption">Don't use a page banner to disproportionately highlight a small feature.</Caption>
  </Dont>
    </DoDontContainer>
    <h3>{`Inline banner`}</h3>
    <p>{`Use inline banners in a page with multiple steps or actions.`}</p>
    <table>
  <tr>
    <td></td>
    <th>Example</th>
    <th>Notes</th>
  </tr>
  <tr>
    <th>Inline feature banner</th>
    <td>
      <img src="https://user-images.githubusercontent.com/6744014/88744364-f5f40480-d0fb-11ea-97c4-5ab87b548a2c.png" alt="Example of an inline feature banner" />
      <p>A short introduction to the Wiki feature while configuring repository settings.</p>
    </td>
    <td>
      Organization, repository, or user settings, where a feature is managed and customized, inline alert banners can
      highlight relevant features that may help better accomplish a user’s task.
    </td>
  </tr>
  <tr>
    <th>Hidden revealed</th>
    <td>
      <img src="https://user-images.githubusercontent.com/6744014/89241458-d35a6380-d5b3-11ea-9307-52c8ae80f2d3.png" alt="Example of an inline banner revealing an easter egg" />
      <p>Discovering the Profile Readme when creating a new repository.</p>
    </td>
    <td>
      Sometimes features can be hidden for users to discover. Show a <a href="">Primer alert</a> at pivotal moments to
      signal the user they're on the right track and provide more information.
    </td>
  </tr>
    </table>
    <h3>{`Empty states`}</h3>
    <p>{`Use empty states as an integrated way to onboard users to new features. Read more about `}<a parentName="p" {...{
        "href": "/ui-patterns/empty-states"
      }}>{`empty states`}</a>{`.`}</p>
    <h4>{`In-product marketing empty state`}</h4>
    <p>{`For special occasions, a first time experience may be more unique than the typical blank state. Take a more branded approach to engage and guide the user through complex experiences. Be aware of how the experience will change once the first-time UI is no longer there.`}</p>
    <Box display="flex" alignItems="center" flexDirection="column" my={3} mdxType="Box">
  <Box borderWidth="1px" borderStyle="solid" borderColor="border.default" borderRadius={2} width={9 / 12} mb={2} overflow="hidden" mdxType="Box">
    <img src="https://user-images.githubusercontent.com/6744014/88744262-b3cac300-d0fb-11ea-9752-c43722a30399.png" alt="Screenshot of the empty state for GitHub actions" />
  </Box>
  <p>GitHub Actions branded first-time user experience</p>
    </Box>
    <h2>{`Designated feature discovery areas`}</h2>
    <p>{`The designated feature discovery areas are generally detached from where the feature actually lives and have a clear click-through for the user to try out the feature or to learn more.`}</p>
    <h3>{`Dashboard feature bulletin`}</h3>
    <p>{`The top of the right sidebar in a user’s dashboard (logged-in home) is a designated area for feature discovery. They take the user directly to the feature in the product or the relevant marketing page.`}</p>
    <p>{`When using a dashboard feature bulletin:`}</p>
    <ul>
      <li parentName="ul">{`Limit messages to around 160 characters.`}</li>
      <li parentName="ul">{`If there is a click-through, include a descriptive button; avoid hidden links.`}</li>
      <li parentName="ul">{`Use color, with discretion, to spotlight and bring brand feature personality to the announcement.`}</li>
    </ul>
    <Box display="flex" alignItems="center" flexDirection="column" my={3} mdxType="Box">
  <img mb={2} src="https://user-images.githubusercontent.com/6744014/89245516-d22e3400-d5bd-11ea-8124-110a698562d2.png" alt="Example of a bulletin that leads to a marketing page" />
  <p>An announcement about GitHub Teams in the feature preview bulletin leads to an off-platform marketing page.</p>
    </Box>
    <h3>{`Feature preview dialog`}</h3>
    <p>{`Feature previews allow teams to test features in production with users who opt in. A feature under private or public preview will be added to the Feature Preview dialog, accessible through the user dropdown.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` Features in preview should have a comprehensive release and redaction plan. No feature should remain in preview for longer than 2 months unmonitored.`}</p>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/6744014/89245313-6b107f80-d5bd-11ea-86cb-b697c61a2d99.png",
        "alt": "Feature preview dialog"
      }}></img></p>
    <h2>{`Lifecycles (labels)`}</h2>
    <p>{`Labels are a softer way to accentuate new features without derailing the user from their primary task.`}</p>
    <p>{`Consider using standalone labels for features that appear in a user’s current view but may navigate away from their current page to fully engage with the feature.
If the label is not part of a navigational item then it's important to include a `}<inlineCode parentName="p">{`Give feedback`}</inlineCode>{` or `}<inlineCode parentName="p">{`Learn more`}</inlineCode>{` link to collect info on how the changes are perceived by the user.`}</p>
    <h3>{`Lifecycles`}</h3>
    <p>{`Depending on the size and impact of your ship you might only release to a fraction of users.
There are currently 4 main lifecycles: `}<inlineCode parentName="p">{`Private preview`}</inlineCode>{`, `}<inlineCode parentName="p">{`Public preview`}</inlineCode>{`, `}<inlineCode parentName="p">{`General availability (GA)`}</inlineCode>{` and `}<inlineCode parentName="p">{`Closing down`}</inlineCode>{`.
The `}<inlineCode parentName="p">{`Private preview`}</inlineCode>{` lifecycle is often referred to as a `}<inlineCode parentName="p">{`Staff ship`}</inlineCode>{` within GitHub.`}</p>
    <p>{`Note: The `}<inlineCode parentName="p">{`Technical preview`}</inlineCode>{` label may sometimes be used by the Next team for experiments and research projects.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Stage`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Details`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://github.com/user-attachments/assets/c509e399-0b44-4009-8fed-708042aa33b8",
              "alt": "private preview"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Private Preview`}</strong>{` `}<br />{`(formerly Alpha, Private Beta, Staff)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Not publicly announced`}<br />{`- Support handled by the product and engineering team (no SLAs)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://github.com/user-attachments/assets/3f549ab1-3b1c-425d-b7c3-95c591bed4fc",
              "alt": "public preview"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Public Preview`}</strong>{` `}<br />{`(formerly Limited Public Beta, Public Beta)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Publicly announced`}<br />{`- Customers should not yet take production dependencies on the service (no SLAs)`}<br />{`- Support handled by the product and engineering teams. With VP Support approval, exceptions allow the Support team to handle tickets.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`General Availability`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Available to ALL GHEC Customers`}<br />{`- SLAs apply (where applicable, some products do not have SLAs)`}<br />{`- Support provided`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`We recommend gathering feedback through a discussion to enable seamless follow-up with the user. This discussion should specify the changes made, providing clarity to the user about what's new. In case of deprecating a feature, it's crucial to provide additional information through our documentation. Note that features that were never promoted beyond the `}<inlineCode parentName="p">{`Private preview`}</inlineCode>{` lifecycle do not need to go through a deprecation lifecycle.`}</p>
    <h3>{`Color and terminology`}</h3>
    <p>{`It's important to stick to the correct terminology and label color when referring to a lifecycle. We no longer use the terms Alpha or Beta to identify stages and instead preview Private preview and Preview in the UI.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://github.com/user-attachments/assets/eeb37741-4b3a-451c-8906-47e7916016f9" alt="public preview label" />
    <Caption mdxType="Caption">Use the correct label and link color</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://user-images.githubusercontent.com/980622/205090963-c5889708-12bc-44ca-8a9e-801612c92656.png" alt="mistaken beta label wrong color" />
    <Caption mdxType="Caption">Don't change the label or link color</Caption>
  </Dont>
    </DoDontContainer>
    <h3>{`Label usage and guidelines`}</h3>
    <p>{`For more information on how to properly use labels in the product, `}<a parentName="p" {...{
        "href": "../components/label/"
      }}>{`please refer to the design guidelines.`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      